import { MobilePlanType } from '@app-types/plan';

import { formatTagText, sendTracking } from '@helpers/tracking';

import { PlanTypeId } from '@constants/plan';

const PLAN_TAG_NAMES: Record<string, string> = {
    [PlanTypeId.SOWO]: 'B&You',
    [PlanTypeId.SOWO_PRO]: 'B&You',

    [PlanTypeId.SIMO]: 'Sensation_sim_seule',
    [PlanTypeId.SIMO_PRO]: 'Sensation_sim_seule',

    [PlanTypeId.PREMIUM]: 'Sensation_avantages_smartphone',
    [PlanTypeId.PREMIUM_PRO]: 'Sensation_avantages_smartphone',
};

export function OfferWallViewTracking(
    isRenewal: boolean,
    isWithSmartphone: boolean,
    isPro: boolean,
    isLogged: boolean,
    isConvergence: boolean,
) {
    const pageTracking = {
        pageCategory: 'Liste_produits_mobile',
        page: `Forfaits_avec_engagement_${isWithSmartphone ? 'avec' : 'sans'}_telephone${isPro ? '_pro' : ''}`,
        marketLine: 'Mobile',
        isB2B: false,
        eCommerceStep: 'Liste_produits',
        variant: {
            configurateurPrix: 'prospect_non_logue',
        },
    };
    if (isLogged) {
        pageTracking.variant.configurateurPrix = `client_${isConvergence ? '' : 'non_'}eligible_convergence`;
    }
    sendTracking(isRenewal, pageTracking);
}

export function sendViewMorePlansTracking(isShowed: boolean) {
    sendTracking(false, {
        action: 'Clic_affichage',
        category: 'Liste_forfait_FSM',
        name: `${isShowed ? 'Derouler' : 'Enrouler'}_forfaits_FSM`,
        eCommerceStep: 'Liste_produits',
    });
}

export function sendLockPlanTracking(isRenewal: boolean, isLocked: boolean) {
    sendTracking(isRenewal, {
        action: 'Clic_affichage',
        category: 'Mur_forfait_bloque',
        name: `icone_forfait_bloque_${isLocked ? 'coche' : 'decoche'}`,
        eCommerceStep: 'Fiche_produit',
    });
}

export function sendPlanDetailsTracking(isRenewal: boolean, plan: MobilePlanType) {
    sendTracking(isRenewal, {
        category: 'Fiche_produit_mobile',
        eCommerceStep: 'Fiche_produit',
        marketLine: 'Mobile',
        page: formatTagText(`Forfait_${plan.name}`),
        products: [
            {
                name: plan.name,
                id: plan.entityId ?? '',
                gencode: plan.gencode,
                sku: plan.gencode ?? '',
                price: plan.price,
                ligneProduit_niv1: 'Forfaits_mobile',
                gamme: PLAN_TAG_NAMES[plan.typeId] ?? '',
                promo: [],
                type_id: plan.typeId,
            },
        ],
    });
}
