'use client';

import Image from 'next/image';
import { useMemo } from 'react';

import { Box, BoxContent, Text, TextLevels, TrilogyColor, TypographyBold } from '@bytel/trilogy-react';

import { FundingType } from '@app-types/funding';

import { InlinePrice } from '@components/inline-price';

import younitedIcon from '@assets/logo-younited.svg';

type FundingDetailsBoxProps = {
    funding: FundingType;
    shouldShowFinancingText?: boolean;
};

export function FundingDetailsBox({ funding, shouldShowFinancingText = false }: Readonly<FundingDetailsBoxProps>) {
    const hasFixedInterestRate = useMemo(
        () => funding?.fixedInterestRate !== undefined && funding.fixedInterestRate >= 0,
        [funding?.fixedInterestRate],
    );

    return (
        <Box shadowless>
            <BoxContent backgroundColor={TrilogyColor.NEUTRAL_FADE}>
                {shouldShowFinancingText && (
                    <Text level={TextLevels.TWO} className='is-flex'>
                        *&nbsp;Financement&nbsp; <Image src={younitedIcon} alt='Younited' width={70} />
                    </Text>
                )}
                <Text level={TextLevels.ONE} marginless>
                    {funding.installmentCount} mensualités de{' '}
                    <InlinePrice amount={funding.monthlyAmount} period='mois' />
                </Text>
                <Text level={TextLevels.ONE} marginless typo={TypographyBold.TEXT_WEIGHT_SEMIBOLD}>
                    Montant total dû&nbsp;: <InlinePrice amount={funding.totalFinancingCost} />
                </Text>
                <Text level={TextLevels.ONE} marginless typo={TypographyBold.TEXT_WEIGHT_SEMIBOLD}>
                    TAEG fixe&nbsp;: {funding.APR.toString().replace('.', ',')}%
                </Text>
                <Text level={TextLevels.ONE} marginless>
                    Montant financé&nbsp;: <InlinePrice amount={funding.amountToFinance} typo={[]} />
                </Text>
                <Text level={TextLevels.ONE} marginless={hasFixedInterestRate}>
                    Intérêts&nbsp;: <InlinePrice amount={funding.interestAmount} typo={[]} />
                </Text>
                {hasFixedInterestRate && (
                    <Text level={TextLevels.ONE}>
                        Taux débiteur fixe&nbsp;: {funding.fixedInterestRate!.toString().replace('.', ',')}%
                    </Text>
                )}

                <Text level={TextLevels.ONE} typo={TypographyBold.TEXT_WEIGHT_SEMIBOLD}>
                    Un crédit vous engage et doit être remboursé. Vérifiez vos capacités de remboursement avant de vous
                    engager.
                </Text>
            </BoxContent>
        </Box>
    );
}
