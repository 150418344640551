'use client';

import { ReactNode } from 'react';

import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from '@bytel/trilogy-react';

import { SSIBlock } from '@components/ssi-block';

type LegalsProps = {
    id: string;
    url: string;
    isItem?: boolean;
};

function LegalsContainer({ id, children, isItem }: { id: string; children: ReactNode; isItem: boolean }) {
    const content = (
        <AccordionItem data-cy='legals' id={`legals-${id}`}>
            <AccordionHeader>Mentions légales</AccordionHeader>
            <AccordionBody>{children}</AccordionBody>
        </AccordionItem>
    );
    if (isItem) {
        return content;
    } else {
        return <Accordion>{content}</Accordion>;
    }
}

export function Legals({ id, url, isItem = false }: LegalsProps) {
    if (!url) {
        return null;
    }

    return (
        <SSIBlock
            tagId={id}
            url={`/partials/mentions_legales/emplacements/${url}`}
            container={LegalsContainer}
            containerProps={{ id, isItem }}
        />
    );
}
