import { Button, ButtonVariant, Modal, ModalBody, ModalFooter, Spacer, SpacerSize } from '@bytel/trilogy-react';

import { WallType } from '@app-types/config';
import { FundingType } from '@app-types/funding';
import { MobilePlanDetailWithProvenanceType } from '@app-types/plan';
import { Product, ProductChildFull, ProductType } from '@app-types/product';

import { Funding, PriceDetailAccessory, PriceDetailPhone } from '@components/product-details-modal';

type ProductDetailModalProps = {
    onClose: () => void;
    type: WallType | ProductType;
    plan?: Partial<MobilePlanDetailWithProvenanceType>;
    selectedFunding?: FundingType;
    modalOpen: boolean;
    currentProduct?: Product | ProductChildFull;
};

export function ProductDetailsModal({
    onClose,
    type,
    plan,
    selectedFunding,
    modalOpen = false,
    currentProduct,
}: ProductDetailModalProps) {
    if (!currentProduct?.details) return null;

    return (
        <Modal active={modalOpen} onClose={onClose} title='Détail du prix'>
            <ModalBody>
                {(type === ProductType.PHONE || type === WallType.PHONE) && (
                    <>
                        <PriceDetailPhone
                            plan={plan}
                            initialPrice={currentProduct.details.price.initial}
                            finalPrice={currentProduct.details.price.final}
                            subsidizedPrice={currentProduct.details.price.subsidized}
                        />
                        <Spacer size={SpacerSize.FOUR} />
                        {currentProduct.details && (
                            <Funding funding={selectedFunding} productDetails={currentProduct.details} />
                        )}
                    </>
                )}
                {(type === ProductType.ACCESSORY || type === WallType.ACCESSORY) && (
                    <PriceDetailAccessory product={currentProduct} />
                )}
            </ModalBody>
            <ModalFooter>
                <Button variant={ButtonVariant.PRIMARY} onClick={onClose}>
                    Fermer
                </Button>
            </ModalFooter>
        </Modal>
    );
}
