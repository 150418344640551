'use client';

import { Divider, Justifiable, Text, TextLevels, TypographyAlign, TypographyBold, View } from '@bytel/trilogy-react';

import { MobilePlanDetailWithProvenanceType } from '@app-types/plan';

import { InlinePrice } from '@components/inline-price';

type PriceDetailProps = {
    plan?: Partial<MobilePlanDetailWithProvenanceType>;
    initialPrice: number;
    finalPrice: number;
    subsidizedPrice?: number;
};

export function PriceDetailPhone({ plan, initialPrice, finalPrice, subsidizedPrice }: PriceDetailProps) {
    const discountWithPlan = subsidizedPrice && plan ? initialPrice - subsidizedPrice : 0;
    const instantDiscount = (subsidizedPrice || initialPrice) - finalPrice;
    return (
        <>
            {discountWithPlan + instantDiscount > 0 && (
                <View flexable justify={Justifiable.SPACE_BETWEEN}>
                    <Text level={TextLevels.TWO}>Prix seul</Text>
                    <InlinePrice typo={[]} level={TextLevels.TWO} amount={initialPrice} />
                </View>
            )}
            {plan && discountWithPlan > 0 && (
                <View flexable justify={Justifiable.SPACE_BETWEEN}>
                    <div>
                        <Text level={TextLevels.TWO} marginless typo={TypographyAlign.TEXT_LEFT}>
                            Remise avec un forfait de {plan.dataEnvelope}
                        </Text>
                        <Text
                            marginless
                            level={TextLevels.THREE}
                            typo={TypographyAlign.TEXT_LEFT}
                            className='capitalize-first'
                        >
                            {plan.obligationLabel}
                        </Text>
                    </div>

                    <InlinePrice typo={[]} level={TextLevels.TWO} amount={-discountWithPlan} />
                </View>
            )}
            {instantDiscount > 0 && (
                <View flexable justify={Justifiable.SPACE_BETWEEN}>
                    <Text level={TextLevels.TWO}>Remise immédiate</Text>
                    <InlinePrice typo={[]} level={TextLevels.TWO} amount={-instantDiscount} />
                </View>
            )}
            {discountWithPlan + instantDiscount > 0 && <Divider />}
            <View flexable justify={Justifiable.SPACE_BETWEEN}>
                <Text level={TextLevels.ONE} marginless typo={TypographyBold.TEXT_WEIGHT_SEMIBOLD}>
                    Prix du téléphone
                </Text>
                <Text level={TextLevels.ONE} marginless typo={TypographyBold.TEXT_WEIGHT_SEMIBOLD}>
                    =&nbsp;
                    <InlinePrice amount={finalPrice ?? 0} />
                </Text>
            </View>
        </>
    );
}
