'use client';

import { Justifiable, Text, TextLevels, View } from '@bytel/trilogy-react';

import { DiscountEnumType } from '@app-types/discount';
import { FundingType } from '@app-types/funding';
import { PriceFundingsDetails } from '@app-types/product';

import { FundingDetailsBox } from '@components/funding-details-box';
import { InlinePrice } from '@components/inline-price';
import { PriceDetailToday, PriceTotalToday } from '@components/product-details-modal';

import { isComptant, isEDP, isYounited } from '@helpers/funding';
import { getDiscountValue } from '@helpers/product';

type FundingProps = {
    productDetails: PriceFundingsDetails;
    funding?: FundingType;
};

export function Funding({ productDetails: { discounts, price }, funding }: FundingProps) {
    const sumODRDiscount = getDiscountValue(discounts, DiscountEnumType.ODR);

    if (!funding && sumODRDiscount > 0) {
        return (
            <>
                <PriceDetailToday value={sumODRDiscount} />
                <PriceTotalToday value={price.final - sumODRDiscount} />
            </>
        );
    } else if (!funding) {
        return null;
    }

    return (
        <>
            {!isComptant(funding.type) && (
                <View flexable justify={Justifiable.SPACE_BETWEEN}>
                    <Text level={TextLevels.TWO}>À régler aujourd&apos;hui</Text>
                    <InlinePrice typo={[]} level={TextLevels.TWO} amount={funding.initialDeposit} />
                </View>
            )}
            {!isYounited(funding.type) && sumODRDiscount > 0 && <PriceDetailToday value={sumODRDiscount} />}
            {(isEDP(funding.type) || isYounited(funding.type)) && (
                <View flexable justify={Justifiable.SPACE_BETWEEN}>
                    <Text>
                        <Text level={TextLevels.TWO} marginless>
                            Puis à payer par mois
                        </Text>
                        <Text level={TextLevels.THREE} marginless>
                            {isYounited(funding.type) ? 'Financement' : 'Facilité '} sur {funding.installmentCount} mois
                            {isYounited(funding.type) ? '\u00A0*' : ''}
                        </Text>
                    </Text>

                    <InlinePrice typo={[]} level={TextLevels.TWO} amount={funding.monthlyAmount} period='mois' />
                </View>
            )}
            {isYounited(funding.type) && sumODRDiscount > 0 && <PriceDetailToday value={sumODRDiscount} />}
            {sumODRDiscount > 0 && (
                <PriceTotalToday
                    value={
                        isYounited(funding.type)
                            ? price.final - sumODRDiscount + funding.interestAmount
                            : price.final - sumODRDiscount
                    }
                />
            )}
            {isYounited(funding.type) && <FundingDetailsBox funding={funding} shouldShowFinancingText />}
        </>
    );
}
