import { getSessionVariable, setSessionVariable } from '@services/storage';

import { CategoryPlanType, MobilePlanType } from '@app-types/plan';

import { sendLockPlanTracking } from './tracking';

export function initBlockedPlans(category: CategoryPlanType) {
    const blockedPlans: { [key: string]: boolean } = JSON.parse(getSessionVariable('blockedPlans') || '{}');
    category.products.forEach((plan: MobilePlanType) => {
        if (!blockedPlans[plan.entityId!]) {
            blockedPlans[plan.entityId!] = plan.locked === '2';
        }
    });
    setSessionVariable('blockedPlans', JSON.stringify(blockedPlans));
    return blockedPlans;
}

export function setPlanLock(entityId: string, locked: boolean, isRenewal: boolean) {
    const blockedPlans: { [key: string]: boolean } = JSON.parse(getSessionVariable('blockedPlans') || '{}');
    blockedPlans[entityId] = locked;
    setSessionVariable('blockedPlans', JSON.stringify(blockedPlans));
    sendLockPlanTracking(isRenewal, locked);
    return blockedPlans;
}

export function getBlockedPlans(): { [key: string]: boolean } {
    return JSON.parse(getSessionVariable('blockedPlans') || '{}');
}

export enum LockedPlanValue {
    NONE = '0',
    ONE = '1',
    TWO = '2',
}
