'use client';

import { useEffect, useState } from 'react';

import { Divider, Justifiable, Text, TextLevels, TypographyBold, View } from '@bytel/trilogy-react';

import { getApiProductDetails } from '@services/products';

import { DiscountEnumType } from '@app-types/discount';
import { Product, ProductChildFull } from '@app-types/product';

import { InlinePrice } from '@components/inline-price';

import { getDiscountValue } from '@helpers/product';
import { noop } from '@helpers/try-catch';

type PriceDetailProps = {
    product: Product | ProductChildFull;
};

export function PriceDetailAccessory({ product }: PriceDetailProps) {
    const [file, setFile] = useState<string>();
    useEffect(() => {
        if (product.id) {
            getApiProductDetails(product.id)
                .then((result) => {
                    setFile(result.odr?.file);
                })
                .catch(noop);
        }
    }, [product]);

    if (!product.details || !product.id) return null;

    const discounts = product.details.discounts;
    const instantDiscount = product.details.price.initial - product.details.price.final;

    const sumODRDiscount = getDiscountValue(discounts, DiscountEnumType.ODR);

    return (
        <>
            <View flexable justify={Justifiable.SPACE_BETWEEN}>
                <Text level={TextLevels.TWO}>
                    {product.brand} {product.name}
                </Text>
                <InlinePrice typo={[]} level={TextLevels.TWO} amount={product.details.price.initial} />
            </View>
            {instantDiscount > 0 && (
                <View flexable justify={Justifiable.SPACE_BETWEEN}>
                    <Text level={TextLevels.TWO} marginless>
                        Remise immédiate
                    </Text>
                    <InlinePrice typo={[]} level={TextLevels.TWO} amount={-instantDiscount} />
                </View>
            )}
            <Divider />
            <View flexable justify={Justifiable.SPACE_BETWEEN}>
                <Text level={TextLevels.ONE} typo={TypographyBold.TEXT_WEIGHT_SEMIBOLD}>
                    Prix de l&apos;accessoire
                </Text>
                <Text level={TextLevels.ONE} marginless typo={TypographyBold.TEXT_WEIGHT_SEMIBOLD}>
                    =&nbsp;
                    <InlinePrice amount={product.details.price.final ?? 0} />
                </Text>
            </View>
            {sumODRDiscount > 0 && (
                <>
                    <View flexable justify={Justifiable.SPACE_BETWEEN}>
                        <div>
                            <Text level={TextLevels.TWO} marginless>
                                Remboursement après achat
                            </Text>
                            {file ? (
                                <Text level={TextLevels.THREE} marginless>
                                    <a className='link' download href={file}>
                                        Télécharger le coupon
                                    </a>
                                </Text>
                            ) : null}
                        </div>

                        <InlinePrice typo={[]} level={TextLevels.TWO} amount={-sumODRDiscount} />
                    </View>
                    <Divider />
                    <View flexable justify={Justifiable.SPACE_BETWEEN}>
                        <Text level={TextLevels.ONE} marginless typo={TypographyBold.TEXT_WEIGHT_SEMIBOLD}>
                            Prix après remboursement
                        </Text>
                        <Text level={TextLevels.ONE} marginless typo={TypographyBold.TEXT_WEIGHT_SEMIBOLD}>
                            =&nbsp;
                            <InlinePrice amount={product.details.price.final - sumODRDiscount} />
                        </Text>
                    </View>
                </>
            )}
        </>
    );
}
