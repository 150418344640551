'use client';

import { usePathname } from 'next/navigation';

import { BreadcrumbItem, Breadcrumb as BreadcrumbTrilogy } from '@bytel/trilogy-react';

import { StructuredData } from '@components/structured-data';

import { getBreadcrumbStructuredData } from '@helpers/structured-data';

export type BreadcrumbElement = {
    name: string;
    label: string;
    href?: string;
    onClick?: () => void;
};

type BreadcrumbProps = {
    elements: BreadcrumbElement[];
};

export function Breadcrumb({ elements }: Readonly<BreadcrumbProps>) {
    const pathname = usePathname();

    return (
        <>
            <StructuredData data={getBreadcrumbStructuredData(pathname, elements)} />
            <BreadcrumbTrilogy data-cy='breadcrumb'>
                {elements.map(({ name, href, label, onClick }, index) => (
                    <BreadcrumbItem key={name} href={href} onClick={onClick} active={index === elements.length - 1}>
                        {label}
                    </BreadcrumbItem>
                ))}
            </BreadcrumbTrilogy>
        </>
    );
}
