import { StickerType, StickersType } from '@bytel/product-wall';

import { getAppConfig } from '@services/config';
import { cmsHttpService } from '@services/http';
import { CACHE_TAGS, HttpService } from '@services/http/http';

import { StaticBannerAnimcoApi } from '@app-types/animco';
import {
    PalpatineBonusPlan,
    PalpatinePlanType,
    PalpatineProduct,
    PalpatineStickerItemType,
    PalpatineStickerType,
} from '@app-types/api/palpatine';
import { StaticCategoryApiResponseBase, StaticProductsAPI, StaticTabsResponse } from '@app-types/api/statics';

import { localApiPaths } from '@helpers/path';

const appConfig = getAppConfig();

export class CmsService {
    private httpService: HttpService;

    public constructor(httpService: HttpService) {
        this.httpService = httpService;
    }

    public async getPartialsCms(path: string): Promise<string | undefined> {
        return this.httpService
            .get<string | undefined>(`${appConfig.assets.cms.url}/${path}`, undefined, {
                ttl: parseInt(getAppConfig().cache.ttl.cms),
                tags: [CACHE_TAGS.CMS],
            })
            .catch(() => undefined);
    }

    public async getProduct(): Promise<{ items: PalpatineProduct[] }> {
        return this.httpService
            .get<{ items: PalpatineProduct[] }>(`${appConfig.assets.palpatine.product}/anim_fiche_produit`, undefined, {
                ttl: parseInt(appConfig.cache.ttl.cms),
                tags: [CACHE_TAGS.CMS],
            })
            .catch(() => {
                return { items: [] };
            });
    }

    public async getBonusPlan(): Promise<PalpatineBonusPlan['forfait_bonus_reprise'] | undefined> {
        return this.httpService
            .get<{ items: PalpatineBonusPlan[] }>(
                `${appConfig.assets.palpatine.product}/fiche_produit_bonus_reprise`,
                undefined,
                {
                    ttl: parseInt(appConfig.cache.ttl.cms),
                    tags: [CACHE_TAGS.CMS],
                },
            )
            .then((response) => response.items[0]?.forfait_bonus_reprise)
            .catch(() => {
                return undefined;
            });
    }

    public async getRocProducts() {
        return this.httpService.get<StaticProductsAPI>(
            `${appConfig.assets.palpatine.fai}/fai_roc_products`,
            undefined,
            {
                ttl: parseInt(appConfig.cache.ttl.cms),
                tags: [CACHE_TAGS.CMS],
            },
        );
    }

    public async getRocTabs() {
        return this.httpService.get<StaticTabsResponse>(`${appConfig.assets.palpatine.fai}/fai_roc_tabs`, undefined, {
            ttl: parseInt(appConfig.cache.ttl.cms),
            tags: [CACHE_TAGS.CMS],
        });
    }

    public async getPack() {
        return this.httpService.get<StaticCategoryApiResponseBase>(
            `${appConfig.assets.palpatine.fai}/fai_samsung_page_pack`,
            undefined,
            {
                ttl: 0,
                tags: [CACHE_TAGS.CMS],
            },
        );
    }

    public async getStickers<T extends PalpatineStickerItemType>(url: string): Promise<StickersType> {
        const response = await this.httpService.get<PalpatineStickerType<T>>(
            `${appConfig.assets.palpatine.sticker}/banners_location/${url}`,
            {
                findByKey: 'slug',
            },
            {
                ttl: parseInt(appConfig.cache.ttl.cms),
                tags: [CACHE_TAGS.CMS],
            },
        );

        const stickers: StickerType[] = response.banners.items.map((item) => ({
            id: item.id.toString(),
            position: item.order,
        }));

        const promises: Promise<{ id: string; position: number; html: string }>[] = [];
        stickers.forEach((sticker) => promises.push(this.getStickerContent(sticker)));

        const stickersWithContent: StickersType = {};

        return Promise.allSettled(promises).then((stickersContentResult) => {
            stickersContentResult.forEach((res) => {
                if (res.status === 'fulfilled') {
                    stickersWithContent[res.value.position] = res.value;
                }
            });

            return stickersWithContent;
        });
    }

    public getBanner(url: string) {
        return this.httpService
            .get<StaticBannerAnimcoApi>(
                `${appConfig.assets.palpatine.banners}/landing_pages_bandeau_emplacement/${url}`,
                {
                    findByKey: 'slug',
                },
                {
                    ttl: parseInt(appConfig.cache.ttl.cms),
                    tags: [CACHE_TAGS.CMS],
                },
            )
            .catch(() => {
                return undefined;
            });
    }

    public getPlanDetails = async (data: string, gencode: string, isRenewal: boolean) => {
        return this.httpService
            .get<PalpatinePlanType | undefined>(localApiPaths.planDetails, {
                gencode,
                dataEnvelope: data.replace(/ /g, '_')?.replace(/à/g, 'a')?.toLowerCase(),
                isRenewal: isRenewal.toString(),
            })
            .catch(() => undefined);
    };

    private async getStickerContent(sticker: {
        position: number;
        id: string;
    }): Promise<{ id: string; position: number; html: string }> {
        return this.httpService
            .get<string>(`${appConfig.assets.cms.url}/sticker/${sticker.id}`, undefined, {
                ttl: parseInt(appConfig.cache.ttl.cms),
                tags: [CACHE_TAGS.CMS],
            })
            .then((res) => {
                return {
                    id: sticker.id,
                    position: sticker.position,
                    html: res,
                };
            });
    }
}

export const cmsService = new CmsService(cmsHttpService);
