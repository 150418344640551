'use client';

import {
    Alignable,
    Divider,
    Icon,
    IconName,
    Justifiable,
    Popover,
    Text,
    TextLevels,
    TypographyBold,
    View,
} from '@bytel/trilogy-react';

import { InlinePrice } from '@components/inline-price';

type PriceDetailProps = {
    value: number;
};

export function PriceDetailToday({ value }: Readonly<PriceDetailProps>) {
    return (
        <View flexable justify={Justifiable.SPACE_BETWEEN}>
            <View flexable align={Alignable.ALIGNED_CENTER}>
                <Text level={TextLevels.TWO} marginless>
                    Remboursement après achat
                </Text>
                <Popover trigger={<Icon name={IconName.INFOS_CIRCLE} />}>
                    Le coupon et les instructions vous seront envoyés par mail après achat.
                </Popover>
            </View>

            <InlinePrice typo={[]} level={TextLevels.TWO} amount={-(value < 0 ? 1 : value)} />
        </View>
    );
}

export function PriceTotalToday({ value }: Readonly<PriceDetailProps>) {
    return (
        <>
            <Divider />
            <View flexable justify={Justifiable.SPACE_BETWEEN}>
                <Text level={TextLevels.ONE} typo={TypographyBold.TEXT_WEIGHT_SEMIBOLD}>
                    Prix après remboursement
                </Text>

                <Text level={TextLevels.ONE} marginless typo={TypographyBold.TEXT_WEIGHT_SEMIBOLD}>
                    =&nbsp;
                    <InlinePrice amount={value} />
                </Text>
            </View>
        </>
    );
}
