'use client';

import { Text, TextLevels, TextMarkup, TextProps, TypographyBold } from '@bytel/trilogy-react';

import { formatPrice } from '@helpers/wording';

interface InlinePriceProps extends TextProps {
    amount?: number;
    period?: string;
    markup?: never;
}

export function InlinePrice({
    amount: _amount,
    level = TextLevels.ONE,
    typo = TypographyBold.TEXT_WEIGHT_SEMIBOLD,
    period,
    ...args
}: Readonly<InlinePriceProps>) {
    const amount = formatPrice(_amount ?? 0);
    const accessibilityLabel = period ? `${amount} par ${period}` : amount;

    return (
        <Text
            level={level}
            markup={TextMarkup.SPAN}
            typo={typo}
            accessibilityLabel={accessibilityLabel}
            marginless
            {...args}
        >
            {amount}
            {period ? `/${period}` : ''}
        </Text>
    );
}
