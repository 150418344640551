'use client';

import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import Markdown from 'react-markdown';

import {
    Box,
    BoxContent,
    Button,
    ButtonList,
    ButtonListProps,
    ButtonVariant,
    Checkbox,
    Column,
    Columns,
    Icon,
    IconName,
    IconSize,
    Link,
    List,
    ListItem,
    Modal,
    ModalBody,
    ModalFooter,
    Row,
    Rows,
    Text,
    TextLevels,
    TextMarkup,
    Title,
    TitleLevels,
    TrilogyColor,
} from '@bytel/trilogy-react';

import { cmsService } from '@services/cms';
import { PlanPrice } from '@services/products';

import { MobilePlanType } from '@app-types/plan';

import { InlinePrice } from '@components/inline-price';
import { SSIBlock } from '@components/ssi-block';

import { LockedPlanValue, getBlockedPlans, setPlanLock } from '@home-mobile/helpers';

type ModalPlanDetailsProps = {
    plan: MobilePlanType;
    price: PlanPrice;
    isOpen: boolean;
    onClose: () => void;
    isRenewal?: boolean;
    showBlockedCheckbox?: boolean;
    children?: ButtonListProps['children'];
};

export function ModalPlanDetails({
    plan,
    isOpen = false,
    onClose,
    isRenewal = false,
    price,
    showBlockedCheckbox = false,
    children,
}: ModalPlanDetailsProps) {
    const [cmsContent, setCmsContent] = useState<string | undefined>(undefined);
    const [isLocked, setIsLocked] = useState(false);

    const title = useMemo(() => {
        let planName = plan.name;
        if (!plan.name.includes('Forfait')) {
            planName = `Forfait ${planName}`;
        }
        if (showBlockedCheckbox && plan.locked === LockedPlanValue.TWO) {
            planName = `${planName} - Forfait bloqué`;
        }
        return planName;
    }, [plan.name, plan.locked]);

    const planTitle = useMemo(() => {
        if (plan.isDarwin) {
            return 'Le forfait qui grandit avec votre ado...';
        } else if (plan.hasLowPrice) {
            return "L'essentiel pour bien communiquer...";
        } else {
            return 'Le plein de gigas, mais pas seulement...';
        }
    }, [plan.isDarwin, plan.hasLowPrice]);

    useEffect(() => {
        cmsService.getPlanDetails(plan.dataEnvelope ?? '', plan.gencode, isRenewal).then((response) => {
            setCmsContent(response?.text_popin);
        });
    }, [plan.gencode, plan.dataEnvelope, isRenewal]);

    useEffect(() => {
        if (plan.entityId) {
            setIsLocked(getBlockedPlans()[plan.entityId] ?? false);
        }
    }, [plan.entityId]);

    return (
        <Modal onClose={onClose} active={isOpen} title={title}>
            <ModalBody>
                <Text level={TextLevels.ONE}>
                    <InlinePrice amount={price.final} period='mois' typo={[]} />
                    {price.promoDuration > 0 && (
                        <>
                            <Text markup={TextMarkup.SPAN}>&nbsp;pendant {price.promoDuration} mois puis&nbsp;</Text>
                            <InlinePrice amount={price.forever} period='mois' typo={[]} />
                        </>
                    )}
                    {plan.obligation && (
                        <>
                            <Text markup={TextMarkup.SPAN}>&nbsp;-&nbsp;</Text>
                            <Text markup={TextMarkup.SPAN} className='capitalize-first is-inline-block' marginless>
                                {plan.obligationLabel}
                            </Text>
                        </>
                    )}
                </Text>
                {showBlockedCheckbox && plan.locked === LockedPlanValue.ONE && (
                    <Checkbox
                        id={`locked-${plan.entityId}`}
                        className='mr-3'
                        label='Forfait bloqué'
                        value={plan.entityId}
                        checked={isLocked}
                        onChange={(value) => {
                            setIsLocked(!isLocked);
                            setPlanLock(value.checkboxValue, !isLocked, isRenewal);
                        }}
                    />
                )}
                <Title level={TitleLevels.FIVE}>{planTitle}</Title>
                {plan.fromAbroad && (
                    <InformationSection
                        icon={IconName.AT_GLOBE}
                        ssiBlock={{
                            link: 'Voir les destinations en Europe',
                            url: '/partials/mobiles/banniere_forfaits-mobiles_destinations_europe',
                            id: 'europeAbroad',
                        }}
                    >
                        <Text marginless markup={TextMarkup.SPAN} className='is-force-overflow-wrap'>
                            {plan.fromAbroad}
                        </Text>
                    </InformationSection>
                )}
                {plan.hasDoubleSim && (
                    <InformationSection icon={IconName.SIM_CARDS}>
                        <Text marginless>
                            Une 2ème carte SIM Internet pour utiliser votre forfait sur un second appareil (tablette,
                            montre...)
                        </Text>
                    </InformationSection>
                )}
                {plan.isDarwin && (
                    <InformationSection icon={IconName.CAREER_COMMITTEES}>
                        <Text marginless>
                            Tous les 6 mois, un nouveau palier, avec appels illimités et plus de gigas (10Go puis 20Go)
                            en France métropolitaine
                        </Text>
                    </InformationSection>
                )}
                {plan.smsMms && (
                    <InformationSection
                        icon={IconName.PHONE_WAITING_CALL}
                        ssiBlock={{
                            link: 'Voir les destinations en Europe',
                            url: '/partials/mobiles/banniere_forfaits-mobiles_destinations_europe',
                            id: 'europeSmsMms',
                        }}
                    >
                        <p className='text is-marginless' dangerouslySetInnerHTML={{ __html: plan.smsMms }} />
                    </InformationSection>
                )}
                {plan.internationalCall && (
                    <InformationSection
                        icon={IconName.PHONE_OUTGOING_CALL}
                        ssiBlock={{
                            link: 'Plus de détails',
                            url: '/partials/mobiles/banniere_forfaits-mobiles_destinations',
                            id: 'internationalCall',
                        }}
                    >
                        <Text markup={TextMarkup.SPAN}>{`${plan.internationalCall} `}</Text>
                    </InformationSection>
                )}
                <Markdown
                    components={{
                        li: ListItem,
                        ul: List,
                    }}
                >
                    {cmsContent}
                </Markdown>
            </ModalBody>
            <ModalFooter>
                <ButtonList>
                    <Button variant={ButtonVariant.SECONDARY} onClick={onClose}>
                        Fermer
                    </Button>
                    <>{children}</>
                </ButtonList>
            </ModalFooter>
        </Modal>
    );
}

type InformationSectionProps = {
    icon: IconName;
    children: ReactNode;
    ssiBlock?: {
        link: string;
        url: string;
        id: string;
    };
};

function InformationSection({ icon, children, ssiBlock }: InformationSectionProps) {
    const [isBlockOpened, setIsBlockOpened] = useState(false);

    return (
        <>
            <Columns mobile>
                <Column narrow>
                    <Icon name={icon} size={IconSize.MEDIUM} />
                </Column>
                <Column>
                    {children}
                    {ssiBlock && (
                        <Text marginless>
                            <Link role='button' onClick={() => setIsBlockOpened(true)}>
                                {ssiBlock.link}
                            </Link>
                        </Text>
                    )}
                </Column>
            </Columns>
            {ssiBlock && isBlockOpened && (
                <Box backgroundColor={TrilogyColor.NEUTRAL_FADE} shadowless>
                    <BoxContent>
                        <Icon
                            className='is-absolute is-right-0 is-top-0 cursor-pointer'
                            name={IconName.TIMES}
                            onClick={() => setIsBlockOpened(false)}
                            size={IconSize.SMALL}
                        />
                        <Rows className='is-marginless'>
                            <Row>
                                <SSIBlock tagId={ssiBlock.id} url={ssiBlock.url} />
                            </Row>
                        </Rows>
                    </BoxContent>
                </Box>
            )}
        </>
    );
}
